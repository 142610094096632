import { useStoreActions } from "../../../lib/model";
import useMe from "./useMe";

export const usePreferences = () => {
  const { me, loading, error } = useMe();
  return {
    data: me?.vpsPreferences,
    loading,
    error,
  };
};

export const useSetPreferences = () => {
  return useStoreActions((s) => s.profile.preferences.setPreferences);
};
