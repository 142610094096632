import React from "react";
import styled from "styled-components";
import { selectColor } from "../../../utils/themeUtils";

const SVG = styled.svg`
  height: 18px;
  width: 16px;

  g {
    stroke: ${selectColor("white")};
  }
  &:hover {
    g {
      stroke: ${selectColor("petrol")};
    }
  }
`;

export interface ProfileIconProps {
  style?: React.CSSProperties;
  className?: string;
}

const ProfileIcon: React.FC<ProfileIconProps> = (props) => (
  <SVG viewBox="0 0 16 18" {...props}>
    <g
      strokeWidth="1.5"
      transform="translate(1 1)"
      fill="none"
      fillRule="evenodd"
      opacity=".9"
    >
      <path d="M13.74 17v-6.02l-2.51-2.24M.23 17v-6.02l2.51-2.24M4 17v-4M10 17v-4" />
      <circle cx="7" cy="4" r="4" />
    </g>
  </SVG>
);

export default ProfileIcon;
