import type { FC } from "react";
import styled from "styled-components";
import { selectFont } from "../../../utils/themeUtils";
import useMe from "../../profile/hooks/useMe";

const Name = styled.span`
  ${selectFont("textDetailsSemiBold")};
`;
const Base = styled.div`
  ${selectFont("textDetails")};
  font-weight: normal;
  display: flex;
  align-items: flex-start;
`;
const Icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    viewBox="0 0 16 18"
    style={{ marginRight: 8 }}
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeWidth="1.5"
      opacity=".9"
      transform="translate(1 1)"
    >
      <path d="M13.74 17v-6.02l-2.5-2.24M.23 17v-6.02l2.51-2.24M4 17v-4M10 17v-4" />
      <circle cx="7" cy="4" r="4" />
    </g>
  </svg>
);
const ProfileLeftNavTopItem: FC<{}> = () => {
  const { me, isLoggedIn } = useMe();
  if (!isLoggedIn) return <>Profil</>;

  return (
    <Base>
      <Icon />
      <div>
        <Name>{me.name}</Name>
        <br />

        {me.username ? `KdNr: ${me.username}` : null}
      </div>
    </Base>
  );
};

export default ProfileLeftNavTopItem;
