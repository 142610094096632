import dynamic from "next/dynamic";
import React, { useRef } from "react";
import styled from "styled-components";
import { iff, selectZIndex } from "../../../utils/themeUtils";
import BubbleBadge from "../../core/components/BubbleBadge";
import CartIcon from "../../icons/components/CartIcon";
import useMe from "../../profile/hooks/useMe";

const CartPopup = dynamic({
  loader: () => import("./CartPopup"),
  ssr: false,
});

const IconWrapper = styled.div<{ disabled: boolean }>`
  ${iff((p) => !p.disabled)`
    cursor: pointer;
  `}
`;

const CartWidgetBase = styled.div<{ disabled?: boolean }>`
  position: relative;
  z-index: ${selectZIndex("cartWidget")};
  cursor: pointer;

  ${iff((p) => p.disabled)`
    cursor: none;
    pointer-events: none;
  `}
`;

const CartWidget: React.FC = () => {
  const { cartCount } = useMe();

  const contentRef = useRef<HTMLDivElement>();
  const disabled = cartCount === 0;

  return (
    <CartWidgetBase disabled={disabled} data-testid="CartWidget">
      <IconWrapper ref={contentRef} disabled={disabled}>
        <BubbleBadge value={cartCount}>
          <CartIcon disabled={disabled} />
        </BubbleBadge>
      </IconWrapper>
      <CartPopup parentRef={contentRef} disabled={disabled} />
    </CartWidgetBase>
  );
};

export default React.memo(CartWidget);
