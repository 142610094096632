import React from "react";
import styled from "styled-components";
import { selectColor } from "../../../utils/themeUtils";

const SVG = styled.svg`
  width: 16px;
  height: 16px;
  g {
    stroke: ${selectColor((p) => p.color)};
  }
`;

export interface SearchIconProps {
  style?: React.CSSProperties;
  className?: string;
  color?: any;
  onClick?: any;
}

const SearchIcon: React.FC<SearchIconProps> = ({
  style,
  className,
  color = "white",
  onClick,
}) => (
  <div onClick={onClick}>
    <SVG viewBox="0 0 15 15" style={style} className={className} color={color}>
      <g transform="matrix(-1 0 0 1 15 1)" stroke="#478392" fill="none">
        <circle cx="9" cy="5" r="5" />
        <path d="M.5 13.5L6 8" />
      </g>
    </SVG>
  </div>
);

export default SearchIcon;
