import { useRouter } from "next/router";
import React, { useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "../../../hooks/useTranslation";
import { useStoreActions } from "../../../lib/model";
import mediaQueries from "../../../utils/mediaQueries";
import {
  hideScrollbars,
  iff,
  selectColor,
  selectCurrentPaletteColor,
  selectFont,
  selectSize,
} from "../../../utils/themeUtils";
import LoadingPlaceholder from "../../core/components/LoadingPlaceholder";
import LoadingWrapper from "../../core/components/LoadingWrapper";
import BackIcon from "../../icons/components/BackIcon";
import CloseIcon from "../../icons/components/CloseIcon";
import NextIcon from "../../icons/components/NextIcon";
import type { Crumb } from "../hooks/useBreadcrumbPaths";
import useBreadcrumbPaths from "../hooks/useBreadcrumbPaths";

import type { InternalNavItem, NavItem, NavItemsQueryType } from "../types";

import LinkWithActive from "./LinkWithActive";

const Base = styled.div`
  ${selectFont("mainNavigation")};
  background-color: ${selectCurrentPaletteColor("passive")};
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Header = styled.div`
  background-color: ${selectColor("dark")};
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  min-height: ${selectSize("mobileNavItemHeight")}px;
  ${mediaQueries.desktop`
    height: ${selectSize("desktopHeaderMainActionsHeight")}px;
  `};
  border-bottom: 1px solid ${selectCurrentPaletteColor("text")};
`;

const NavWrapper = styled.div<{ scrollIndex: number }>`
  flex: 1;
  width: 100%;

  display: flex;
  flex-direction: row;
  transform: translateX(-${(p) => 100 * p.scrollIndex}%);
  transition: 0.3s;
  height: 100%;
  overflow: visible;
`;

export interface MainNavigationMobileProps {
  style?: any;
  className?: string;
  NavItemsQuery: NavItemsQueryType;
}

const linkStyle = css`
  ${selectFont("mainNavigation")};
  padding: 13px 15px;
  cursor: pointer;
  color: ${selectCurrentPaletteColor("text")};
  display: flex;
  align-items: flex-start;
  text-decoration: none;

  min-height: ${selectSize("mobileNavItemHeight")}px;
  border-bottom: 1px solid;
`;
const LI = styled.li``;
const A = styled.a`
  ${linkStyle};
  background-color: ${selectCurrentPaletteColor("passive")};
  &:hover {
    background-color: ${selectCurrentPaletteColor("active")};
  }
  ${iff((p) => p.isChildActive || p.isActive)`
   background-color: ${selectCurrentPaletteColor("active")};
  `}
`;

const OverviewA = styled(A)`
  flex-direction: column;
`;

const linkAttrs = () => ({
  children: (
    <LoadingPlaceholder font="mainNavigation" style={{ width: "65%" }} />
  ),
});
const LinkPlaceholder = styled.div.attrs(linkAttrs)`
  padding: 13px 15px;
  border-bottom: 1px solid;
  min-height: ${selectSize("mobileNavItemHeight")}px;
  color: ${selectCurrentPaletteColor("text")};
`;

const closeButtonPadding = 8;

const CloseButton = styled.button<{ onClick: any }>`
  display: inline-flex;
  border: none;
  appearance: none;
  background: none;
  cursor: pointer;
  padding: ${closeButtonPadding}px;
  margin-left: -${closeButtonPadding / 2 + 2}px;
`;

const BackButton = styled.a`
  cursor: pointer;
  ${linkStyle};
  background-color: ${selectColor("dark")};

  width: 100%;
`;

const PageBase = styled.div`
  flex: 1;
  overflow: auto;
  min-width: 100%;
  height: 100%;
  padding-bottom: 150px;
  ${hideScrollbars};
`;

const StyledNextIcon = styled(NextIcon).attrs({ color: "white" })`
  height: 29px;
  flex: 0 0 8px;
  margin-left: auto;
`;

const StyledBackIcon = styled(BackIcon).attrs({ color: "white" })`
  height: 29px;
  flex: 0 0 8px;
  margin-right: 15px;
`;
type GotoPage = (index: number, crumb: Crumb) => void;
type GotoParent = (index: number) => void;

const OverviewLabel = styled.div`
  ${selectFont("contextNavigation")};
  color: ${selectColor("grey")};
  margin-bottom: 4px;
`;

const NavigationPage: React.FC<{
  navItems: NavItem[];
  currentNavItem: InternalNavItem;
  gotoPage: GotoPage;
  gotoParent: GotoParent;
  index: number;
  loading: boolean;
}> = ({ navItems, currentNavItem, gotoPage, gotoParent, index, loading }) => {
  const hideMobileNav = useStoreActions((s) => s.navigation.hideMobileNav);
  const { t } = useTranslation("navigation");

  const transitionKey = navItems.length
    ? "navigation"
    : loading
    ? "loading"
    : "empty";
  return (
    <PageBase>
      {index > 0 ? (
        <>
          <BackButton onClick={() => gotoParent(index)}>
            <StyledBackIcon />
            {t("mobileNav.back")}
          </BackButton>

          {currentNavItem ? (
            <LinkWithActive href={currentNavItem.href} as={currentNavItem.path}>
              <OverviewA onClick={() => hideMobileNav()}>
                {currentNavItem.title}
                <OverviewLabel>{t("mobileNav.displayOverview")}</OverviewLabel>
              </OverviewA>
            </LinkWithActive>
          ) : null}
        </>
      ) : null}

      <LoadingWrapper transitionKey={transitionKey}>
        {transitionKey === "navigation" ? (
          <ul>
            {navItems.map((item, i) => (
              <LI key={i}>
                {item.type === "internal" ? (
                  <LinkWithActive href={item.href} as={item.path}>
                    {item.hasChildren ? (
                      <A
                        onClick={(e) => {
                          e.preventDefault();
                          gotoPage(index, {
                            path: item.path,
                            slug: item.slug,
                          });
                        }}
                      >
                        {item.title} <StyledNextIcon />
                      </A>
                    ) : (
                      <A onClick={() => hideMobileNav()}>{item.title}</A>
                    )}
                  </LinkWithActive>
                ) : (
                  <A
                    onClick={() => {
                      hideMobileNav();
                      item.onClick();
                    }}
                  >
                    {item.title}
                  </A>
                )}
              </LI>
            ))}
          </ul>
        ) : transitionKey === "loading" ? (
          <div>
            <LinkPlaceholder />
            <LinkPlaceholder />
            <LinkPlaceholder />
          </div>
        ) : null}
      </LoadingWrapper>
    </PageBase>
  );
};

const NavigationPageItem: React.FC<{
  NavItemsQuery: NavItemsQueryType;
  crumb: Crumb;
  index: number;
  gotoPage: GotoPage;
  gotoParent: GotoParent;
}> = React.memo(({ crumb, NavItemsQuery, gotoPage, gotoParent, index }) => (
  <NavItemsQuery path={crumb.path} slug={crumb.slug}>
    {({ navItems, currentNavItem, loading }) =>
      index === 0 || currentNavItem?.hasChildren || loading ? (
        <NavigationPage
          index={index}
          navItems={navItems}
          currentNavItem={currentNavItem}
          gotoPage={gotoPage}
          gotoParent={gotoParent}
          loading
        />
      ) : (
        // we dont show the last page when it has no children
        // but since we don't know that in advance, scrollIndex might be wrong
        // so instead of rendering nothing, we add an empty page BEFORE any other page
        <PageBase style={{ order: -1 }} />
      )
    }
  </NavItemsQuery>
));

const MainNavigationMobile: React.FC<MainNavigationMobileProps> = ({
  style,
  className,
  NavItemsQuery,
}) => {
  const router = useRouter();
  const routePath = (router.query?.menuPath ?? router?.asPath) as string;

  const breadcrumbPaths = useBreadcrumbPaths(routePath);
  const [currentPath, setCurrentPath] = useState<Crumb[]>([]);
  const scrollIndex = currentPath.length - 1;

  useEffect(() => {
    // whenever route changes, update the path
    setCurrentPath(breadcrumbPaths);
  }, [breadcrumbPaths]);

  const gotoPage = useCallback((index: number, crumb: Crumb) => {
    setCurrentPath((path) => [...path.slice(0, index + 1), crumb]);
  }, []);

  const gotoParent = useCallback((index) => {
    setCurrentPath((path) => path.slice(0, index));
  }, []);

  const hideMobileNav = useStoreActions((s) => s.navigation.hideMobileNav);
  return (
    <Base style={style} className={className}>
      <Header>
        <CloseButton onClick={hideMobileNav}>
          <CloseIcon color="white" />
        </CloseButton>
      </Header>
      <NavWrapper scrollIndex={scrollIndex}>
        {currentPath.map((crumb, index) => (
          <NavigationPageItem
            NavItemsQuery={NavItemsQuery}
            key={index}
            crumb={crumb}
            index={index}
            gotoPage={gotoPage}
            gotoParent={gotoParent}
          />
        ))}
        <PageBase /> {/* this page was intentionally left empty */}
      </NavWrapper>
    </Base>
  );
};
export default React.memo(MainNavigationMobile);
