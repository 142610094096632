import React from "react";
import styled from "styled-components";
import { useTranslation } from "../../../hooks/useTranslation";
import type { Font } from "../../../config/theme";
import { MenuId } from "../../../types/global-types";
import mediaQueries from "../../../utils/mediaQueries";
import {
  iff,
  selectColor,
  selectCurrentPaletteColor,
  selectFont,
  selectSize,
} from "../../../utils/themeUtils";
import HorizontalScroller from "../../layout/components/HorizontalScroller";
import PageColumn from "../../layout/components/PageColumn";
import PaletteThemeProvider from "../../layout/components/PaletteThemeProvider";

import useMenuPages from "../hooks/useMenuPages";
import usePageBaseProps from "../hooks/usePageBaseProps";
import type { ExternalNavItem, InternalNavItem } from "../types";
import makeNavItemFromPage from "../utils/makeNavItemFromPage";
import type { ChildIsActiveFunc } from "./LinkWithActive";
import LinkWithActive from "./LinkWithActive";

const Base = styled.div`
  background-color: ${selectColor("grey")};
  height: ${selectSize("mobileHeaderContextNavHeight")}px;
  position: relative;
  z-index: 2;
  ${mediaQueries.desktop`
    height: ${selectSize("desktopHeaderContextNavHeight")}px;
  `};
`;

const Content = styled(PageColumn)`
  display: flex;
  flex: 1;
`;

const ContextNavBase = styled.div`
  display: flex;
  flex-direction: row;
  & > * {
    margin-right: 15px;
  }

  ${mediaQueries.desktop`
    & > * {
      margin-right: 25px;
    }
  `}
`;
const MetaNavBase = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row-reverse;
  & > * {
    margin-left: 25px;
  }
`;

const Item = styled.a<{ font?: Font }>`
  padding-top: 9px;
  ${selectFont((p) => p.font || "contextNavigation")};
  color: ${selectColor("textWhite")};
  text-decoration: none;
  white-space: nowrap;
  margin-bottom: auto;

  &:hover {
    border-bottom: 1.5px solid ${selectCurrentPaletteColor("active")};
  }
  ${iff((p: any) => p.isActive || p.isChildActive)`
    border-bottom: 1.5px solid ${selectCurrentPaletteColor("active")};
  `}
`;

export interface HeaderContextNavProps {}

type InternalNavItemWithFont = {
  font?: Font;
  customChildIsActive?: ChildIsActiveFunc;
} & InternalNavItem;

const InternalNavItemLink = ({
  href,
  path,
  title,
  font,
  customChildIsActive,
}: InternalNavItemWithFont) => (
  <LinkWithActive
    key={href}
    href={href}
    as={path}
    customChildIsActive={customChildIsActive}
  >
    <Item font={font}>{title}</Item>
  </LinkWithActive>
);

const HeaderContextNav: React.FC<HeaderContextNavProps> = () => {
  const pages = useMenuPages({ menuId: MenuId.MAIN })?.pages ?? [];
  const metaPages = useMenuPages({ menuId: MenuId.META })?.pages ?? [];
  const { t } = useTranslation("navigation");
  // also load basic landing page info, so thats in the cache
  usePageBaseProps("/");

  const STATIC_PAGES: InternalNavItemWithFont[] = [
    {
      type: "internal",
      href: "/",
      path: "/",
      slug: "shop",
      title: t(["shop.title", "shop.navigationTitle"]),
      palette: "shop",
      customChildIsActive: (path) => path.startsWith("/shop"),
    },
  ];

  const STATIC_META_PAGES_TAIL: Array<InternalNavItem | ExternalNavItem> = [
    {
      href: "https://blog.veloplus.ch",
      type: "external",
      title: "Blog",
    },
  ];

  return (
    <Base>
      <HorizontalScroller fadeColor="grey" iconColor="white">
        <Content>
          <ContextNavBase>
            {[...STATIC_PAGES, ...pages.map(makeNavItemFromPage)].map(
              (item, index) => (
                <PaletteThemeProvider key={index} paletteKey={item.palette}>
                  <InternalNavItemLink {...item} />
                </PaletteThemeProvider>
              ),
            )}
          </ContextNavBase>
          <MetaNavBase>
            {[...metaPages.map(makeNavItemFromPage), ...STATIC_META_PAGES_TAIL]
              .reverse() // its floated right to left, so we reverse the pages
              .map((item, index) => (
                <PaletteThemeProvider paletteKey={item.palette} key={index}>
                  {item.type === "external" ? (
                    <Item
                      font="metaNavigation"
                      target="_blank"
                      href={item.href}
                    >
                      {item.title}
                    </Item>
                  ) : (
                    <InternalNavItemLink
                      font="metaNavigation"
                      customChildIsActive={() => false} // don't underline when a child is acive, because the meta pages might show pages that are already related
                      {...(item as InternalNavItem)}
                    />
                  )}
                </PaletteThemeProvider>
              ))}
          </MetaNavBase>
        </Content>
      </HorizontalScroller>
    </Base>
  );
};

export default React.memo(HeaderContextNav);
