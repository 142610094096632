import { useTranslation } from "../../../hooks/useTranslation";
import { PROFILE_PATH } from "../../../config/locations";
import { useStoreActions } from "../../../lib/model";
import type {
  InternalNavItem,
  NavItemsQueryType,
} from "../../navigation/types";
import useMe from "../../profile/hooks/useMe";
import { PROFILE_NAV_ITEM } from "./ShopRootNavItemsQuery";

const PROFILE_ITEMS: Array<{ key: string; enforceUsername: boolean }> = [
  { key: "index", enforceUsername: false },
  { key: "password", enforceUsername: false },
  { key: "emails", enforceUsername: false },
  { key: "open-orders", enforceUsername: false },
  { key: "purchases", enforceUsername: true },
  // { key: "newsletter", enforceUsername: false }, // FIXME: temporary disabled, see https://git.panter.ch/manul/vps/veloplus-shop/-/issues/774
  { key: "magazine", enforceUsername: true },
  { key: "velocard", enforceUsername: true },
];

const ShopProfileNavItemsQuery: NavItemsQueryType = ({ children }) => {
  const logout = useStoreActions((actions) => actions.profile.user.logout);
  const { t } = useTranslation("navigation");
  const { isLoggedIn, hasUsername, me } = useMe();

  if (!isLoggedIn) {
    return children({
      currentNavItem: PROFILE_NAV_ITEM,
      navItems: [],
    });
  }

  const profileItems = PROFILE_ITEMS.filter(
    ({ enforceUsername }) => hasUsername || !enforceUsername,
  );

  return children({
    currentNavItem: PROFILE_NAV_ITEM,
    navItems: [
      ...profileItems.map(
        ({ key }) =>
          // tslint:disable-next-line:no-object-literal-type-assertion
          ({
            title: t(`profile.${key}.navigationTitle`),
            path: `${PROFILE_PATH}/${key === "index" ? "" : key}`,
            href: `${PROFILE_PATH}/${key === "index" ? "" : key}`,
            slug: key,
            type: "internal",
          } as InternalNavItem),
      ),
      ...(me?.adminResources?.length > 0
        ? [
            {
              title: "Admin",
              path: "/admin",
              href: "/admin",
              slug: "admin",
              type: "internal" as const,
            },
          ]
        : []),
      {
        title: t("profile.logout.label"),
        onClick: () => logout(),
        type: "action",
      },
    ],
  });
};

export default ShopProfileNavItemsQuery;
