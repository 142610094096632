import type {
  Color,
  Size,
  ThemeGroup,
  ThemeKeyOrFunc,
  TransformFunc,
} from "../config/theme";
import { select } from "../utils/themeUtils";

import useTheme from "./useTheme";

export const useThemeProperty = <T extends string>(
  group: ThemeGroup,
  themeKeyOrFunc: ThemeKeyOrFunc<T>,
  transform: TransformFunc,
) => {
  const theme = useTheme();
  return select(group, themeKeyOrFunc, transform)({ theme });
};

export const useColor = (
  themeKeyOrFunc: ThemeKeyOrFunc<Color>,
  transform?: TransformFunc,
) => useThemeProperty("colors", themeKeyOrFunc, transform);

export const useSize = (
  themeKeyOrFunc: ThemeKeyOrFunc<Size>,
  transform?: TransformFunc,
) => useThemeProperty("sizes", themeKeyOrFunc, transform);
