import type { ReactNode } from "react";
import React, { useEffect, useState } from "react";
import { Transition } from "react-transition-group";
import type { TransitionStatus } from "react-transition-group/Transition";
import styled, { keyframes } from "styled-components";
import { iff, selectColor, selectFont } from "../../../utils/themeUtils";

const Base = styled.div`
  position: relative;
`;

const bubbleUp = keyframes`
  0%   { transform: scale(1)    translateX(50%) }
  17%  { transform: scale(1.15)   translateX(50%) }
  53%  { transform: scale(.9)   translateX(50%) }
  90%  { transform: scale(1.05) translateX(50%) }
  100% { transform: scale(1)    translateX(50%) }

`;

const Badge = styled.span<{ inverted: boolean; state: TransitionStatus }>`
  background-color: ${selectColor((p) =>
    p.inverted ? "white" : "veloplusRot",
  )};
  color: ${selectColor((p) => (p.inverted ? "black" : "textWhite"))};
  ${selectFont("cartCount")};
  position: absolute;
  top: -5px;
  right: 0px;

  transform: translateX(50%);
  height: 15px;
  padding-right: 5px;
  padding-left: 5px;
  border-radius: 7.5px;
  pointer-events: none;
  display: flex;
  align-items: center;

  ${iff((p) => p.state === "entered")`
    animation: ${bubbleUp} 700ms cubic-bezier(0.28, 0.84, 0.42, 1);
  `}
`;

export interface BubbleBadgeProps {
  style?: React.CSSProperties;
  className?: string;
  inverted?: boolean;
  value: ReactNode;
}

const BubbleBadge: React.FC<BubbleBadgeProps> = ({
  style,
  className,
  inverted = false,
  value,
  children,
}) => {
  const [animate, setAnimate] = useState(false);

  if (process.browser) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (animate || !value) {
        return;
      }

      setAnimate(true);

      let timerId = setTimeout(() => {
        timerId = null;
        setAnimate(false);
      }, 800);

      return () => timerId && clearTimeout(timerId);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);
  }

  return (
    <Base style={style} className={className} data-testid="CartIconWithBadge">
      {children}
      {value ? (
        <Transition timeout={0} in={animate}>
          {(state) => {
            return (
              <Badge inverted={inverted} state={state}>
                {value}
              </Badge>
            );
          }}
        </Transition>
      ) : null}
    </Base>
  );
};

export default BubbleBadge;
