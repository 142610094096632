import React from "react";
import styled from "styled-components";
import { selectCurrentPaletteColor } from "../../../utils/themeUtils";

const SIZE = 40;
const Base = styled.button`
  flex-shrink: 0;
  width: ${SIZE}px;
  height: ${SIZE}px;
  cursor: pointer;
  border-radius: 50%;
  border: none;
  background-color: ${selectCurrentPaletteColor("passive")};
  &:hover {
    background-color: ${selectCurrentPaletteColor("active")};
  }
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Line = styled.div`
  height: 2px;
  width: 17px;
  background-color: ${selectCurrentPaletteColor("text")};
  margin-top: 2.5px;
  margin-bottom: 2.5px;
`;

export interface HamburgerProps {
  style?: React.CSSProperties;
  className?: string;
  onClick: any;
}

const Hamburger: React.FC<HamburgerProps> = ({ style, className, onClick }) => (
  <Base style={style} className={className} onClick={onClick}>
    <Line />
    <Line />
    <Line />
  </Base>
);

export default Hamburger;
