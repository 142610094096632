import React from "react";
import styled from "styled-components";

const SVG = styled.svg`
  height: 17px;
  width: 19px;
`;

export interface WishListIconProps {
  style?: React.CSSProperties;
  className?: string;
}

const WishListIcon: React.FC<WishListIconProps> = ({ style, className }) => (
  <SVG viewBox="0 0 19 17" style={style} className={className}>
    <path
      d="M2.60502 8.26172l.61227.623 6.11995 6.22994 6.12064-6.22994.61227-.623c1.69558-1.72576 1.69558-4.50348 0-6.22924-1.69488-1.72576-3.99866-1.21793-6.12064 1l-.61227.623-.61158-.623c-2.22564-2.21793-4.42506-2.72576-6.12064-1-1.69558 1.72576-1.69558 4.50348 0 6.22924z"
      stroke="#FFF"
      strokeWidth="1.5"
      fill="none"
      fillRule="evenodd"
    />
  </SVG>
);

export default WishListIcon;
