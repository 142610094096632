import { PROFILE_PATH } from "../../../config/locations";
import type {
  InternalNavItem,
  NavItemsQueryType,
} from "../../navigation/types";
import makeNavItemFromAssortment from "../../navigation/utils/makeNavItemFromAssortment";
import useMe from "../../profile/hooks/useMe";
import useRootAssortments from "../hooks/useRootAssortments";
import ProfileLeftNavTopItem from "./ProfileLeftNavTopItem";

export const PROFILE_NAV_ITEM: InternalNavItem = {
  title: "Profil",
  CustomComponent: ProfileLeftNavTopItem,
  href: PROFILE_PATH,
  path: PROFILE_PATH,
  slug: "profil",
  hasChildren: true,
  hideOnDesktop: true,
  type: "internal",
};
const ShopRootNavItemsQuery: NavItemsQueryType = ({ children }) => {
  const { assortments } = useRootAssortments();
  const { isLoggedIn } = useMe();

  if (assortments) {
    return children({
      navItems: [
        ...assortments.map((a) => makeNavItemFromAssortment(a)),
        ...(isLoggedIn ? [PROFILE_NAV_ITEM] : []),
      ],
    });
  }
  return null;
};

export default ShopRootNavItemsQuery;
