export const getFullProductTitle = (
  baseProduct: {
    texts: {
      title: string;
    };
  },
  colorOption: { vendorName: string },
) =>
  `${baseProduct?.texts?.title ?? ""}${
    colorOption?.vendorName ? `, ${colorOption.vendorName}` : ""
  }`;
