import React from "react";
import styled from "styled-components";
import mediaQueries from "../../../utils/mediaQueries";
import ContentColumn from "./ContentColumn";
import PageColumn from "./PageColumn";

const ContentLeft = styled.div`
  min-width: 226px;
  margin-right: 56px;
  display: none;
  ${mediaQueries.desktop`
     display: block;
`};

  margin-top: 70px; /* currently a bit arbitrary */
`;

const ContentRight = styled.div`
  flex: 1;
  min-width: 0; /* this fix is required for text-overflow: ellipsis to work in child elements */
  width: 100%;
`;

const BreadcrumbsWrapper = styled.div`
  margin-top: 27px; // not sure why this value
  margin-bottom: 22px;
  flex-direction: column;
  ${mediaQueries.desktop`
    flex-direction: row;
    flex-wrap: wrap;
  `}

  display: flex;
  gap: 5px 30px;
`;

const ContentLayout = styled(ContentColumn)`
  display: flex;
  flex-direction: row;
  padding-bottom: 120px;
`;

export interface PageContentLayoutProps {
  fullWidthHeader?: any;
  desktopLeftContent?: any;
  showBreadcrumbs?: boolean;
  renderBreadcrumb?: () => React.ReactNode;
}

const ContentWrapper = ({ children }) => (
  <PageColumn>
    <ContentLayout>{children}</ContentLayout>
  </PageColumn>
);

const PageContentLayout: React.FC<PageContentLayoutProps> = ({
  desktopLeftContent,
  renderBreadcrumb,
  children,
  fullWidthHeader = null,
  showBreadcrumbs = true,
}) => {
  return (
    <>
      {fullWidthHeader}
      <ContentWrapper>
        {desktopLeftContent ? (
          <ContentLeft>{desktopLeftContent}</ContentLeft>
        ) : null}

        <ContentRight>
          {showBreadcrumbs ? (
            <BreadcrumbsWrapper>
              {renderBreadcrumb ? renderBreadcrumb() : null}
            </BreadcrumbsWrapper>
          ) : null}
          {children}
        </ContentRight>
      </ContentWrapper>
    </>
  );
};

export default PageContentLayout;
