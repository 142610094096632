import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { AssortmentWithChildrenParts } from "../../product/queries";
import type {
  UseAssortmentWithChildren,
  UseAssortmentWithChildrenVariables,
} from "./types/UseAssortmentWithChildren";

export const QUERY = gql`
  query UseAssortmentWithChildren($path: String, $assortmentId: ID) {
    assortment(slug: $path, assortmentId: $assortmentId) {
      ...AssortmentWithChildrenParts
    }
  }
  ${AssortmentWithChildrenParts}
`;

const useAssortmentWithChildren = ({
  path,
  assortmentId,
}: {
  path?: string;
  assortmentId?: string;
}) => {
  const { data, error, loading } = useQuery<
    UseAssortmentWithChildren,
    UseAssortmentWithChildrenVariables
  >(QUERY, {
    variables: { path, assortmentId },
    skip: !path && !assortmentId,
  });
  const assortment = data?.assortment;

  return { assortment, error, loading };
};

export default useAssortmentWithChildren;
