import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import mediaQueries from "../../../utils/mediaQueries";
import {
  iff,
  selectCurrentPaletteColor,
  selectFont,
  selectSize,
} from "../../../utils/themeUtils";
import PageColumn from "../../layout/components/PageColumn";
import useBreadcrumbPaths from "../hooks/useBreadcrumbPaths";
import type { ActionNavItem, NavItemsQueryType } from "../types";
import LinkWithActive from "./LinkWithActive";

const MegaDropDown = dynamic({
  loader: () => import("./MegaDropDown"),
  ssr: false, // exclude from ssr, as it fetches a lot of ddata
});

const Base = styled.div`
  background-color: ${selectCurrentPaletteColor("passive")};
  width: 100%;
  height: ${selectSize("desktopMainNavHeight")}px;
  display: none;
  position: relative;
  ${mediaQueries.desktop`
   display: block;
  `};
`;

const Content = styled(PageColumn)`
  height: 100%;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  margin-left: -20px;
  margin-right: -20px;
`;

export interface MainNavigationDesktopProps {
  style?: React.CSSProperties;
  className?: string;

  NavItemsQuery: NavItemsQueryType;
}

const A = styled.a<{ dontShowActive?: boolean; showDropDown?: boolean }>`
  display: block;
  height: 100%;
  ${selectFont("mainNavigation")};
  color: ${selectCurrentPaletteColor("text")};
  text-decoration: none;

  padding-left: 20px;
  padding-right: 20px;
  padding-top: 16px;
  white-space: nowrap;

  &:hover {
    background-color: ${selectCurrentPaletteColor("active")};
  }
  ${iff(
    (p: any) =>
      p.showDropDown || (!p.dontShowActive && (p.isActive || p.isChildActive)),
  )`
    background-color: ${selectCurrentPaletteColor("active")};
  `}
`;

const ItemWrapper = styled.div`
  height: 100%;
`;

const ActionItem = ({ item }: { item: ActionNavItem }) => (
  <ItemWrapper>
    <A onClick={item.onClick}>{item.title}</A>
  </ItemWrapper>
);
const Item = ({
  NavItemsQuery,
  item,
  onMouseEnter,
  onMouseLeave,
  showDropDown,
  onItemClick,
  dontShowActive = false,
}) => {
  return (
    <ItemWrapper
      key={item.href}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <LinkWithActive href={item.href} as={item.path}>
        <A
          dontShowActive={dontShowActive}
          onClick={onItemClick}
          showDropDown={showDropDown}
        >
          {item.title}
        </A>
      </LinkWithActive>
      {item.hasChildren ? (
        <MegaDropDown
          visible={showDropDown}
          item={item}
          NavItemsQuery={NavItemsQuery}
          onItemClick={onItemClick}
        />
      ) : null}
    </ItemWrapper>
  );
};
const MainNavigationDesktop: React.FC<MainNavigationDesktopProps> = ({
  style,
  className,
  NavItemsQuery,
}) => {
  const router = useRouter();
  const routePath = (router.query?.menuPath ?? router?.asPath) as string;

  const breadcrumbPaths = useBreadcrumbPaths(routePath);

  // we are interested in the top level
  const topLevelPage = breadcrumbPaths[0];

  const [onHoverItem, setOnHoverItem] = useState<string>();
  const DELAY = 150;
  const [activeDropdown, setActiveDropdown] = useState<string>();
  useEffect(() => {
    let timeout;
    if (onHoverItem) {
      if (!activeDropdown) {
        // nothing active, wait a bit before showing it
        timeout = setTimeout(() => {
          setActiveDropdown(onHoverItem);
        }, DELAY);
      } else {
        // already one active, wait a bit
        timeout = setTimeout(() => {
          setActiveDropdown(onHoverItem);
        }, DELAY);
      }
    } else {
      timeout = setTimeout(() => {
        setActiveDropdown(null);
      }, DELAY);
    }
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onHoverItem]);
  return (
    <Base style={style} className={className}>
      <Content>
        <Inner>
          {NavItemsQuery ? (
            <NavItemsQuery path={topLevelPage.path} slug={topLevelPage.slug}>
              {({ navItems }) => (
                <>
                  {navItems.map((item, index) => {
                    if (item.hideOnDesktop) {
                      return null;
                    }
                    if (item.type === "internal") {
                      return (
                        <Item
                          dontShowActive={Boolean(activeDropdown)}
                          NavItemsQuery={NavItemsQuery}
                          onMouseEnter={() => setOnHoverItem(item.path)}
                          onMouseLeave={() => {
                            if (item.path === onHoverItem) {
                              setOnHoverItem(null);
                            }
                          }}
                          showDropDown={item.path === activeDropdown}
                          onItemClick={() => {
                            setActiveDropdown(null);
                          }}
                          item={item}
                          key={index}
                        />
                      );
                    }
                    if (item.type === "action") {
                      return <ActionItem key={index} item={item} />;
                    }
                  })}
                </>
              )}
            </NavItemsQuery>
          ) : null}
        </Inner>
      </Content>
    </Base>
  );
};

export default React.memo(MainNavigationDesktop);
