import React from "react";
import styled from "styled-components";
import { iff, selectColor } from "../../../utils/themeUtils";

const SVG = styled.svg<{ disabled?: boolean }>`
  height: 18px;
  width: 25px;
  circle {
    fill: ${selectColor("white")};
  }
  path {
    stroke: ${selectColor("white")};
  }
  &:hover {
    circle {
      fill: ${selectColor("petrol")};
    }
    path {
      stroke: ${selectColor("petrol")};
    }
  }

  ${iff((p) => p.disabled)`
    circle {
      fill: ${selectColor("darkDisabled")};
    }
    path {
      stroke: ${selectColor("darkDisabled")};
    }
  `}
`;

export interface CartIconProps {
  style?: React.CSSProperties;
  className?: string;
  disabled?: boolean;
}

const CartIcon: React.FC<CartIconProps> = ({ style, className, disabled }) => (
  <SVG
    viewBox="0 0 25 18"
    style={style}
    className={className}
    disabled={disabled}
  >
    <g transform="translate(0 1)" fill="none" fillRule="evenodd">
      <circle fill="white" cx="18.5" cy="15.5" r="1.5" />
      <circle fill="white" cx="11.5" cy="15.5" r="1.5" />
      <path
        stroke="black"
        strokeWidth="1.5"
        d="M0 .55h5.06l2.83 11.94h13.37l2.77-8.7M9.06 5.56h11.92M9.37 8.66h11"
      />
    </g>
  </SVG>
);

export default CartIcon;
