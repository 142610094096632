import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

import { AssortmentParts } from "../../product/queries";
import type { UseRootAssortments } from "./types/UseRootAssortments";

export const QUERY = gql`
  query UseRootAssortments {
    assortments {
      ...AssortmentParts
    }
  }
  ${AssortmentParts}
`;

const useRootAssortments = () => {
  const { data, error, loading } = useQuery<UseRootAssortments>(QUERY);
  const assortments = data && data.assortments;

  return { assortments, error, loading };
};

export default useRootAssortments;
