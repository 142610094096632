import Link from "next/link";
import React from "react";
import styled, { css } from "styled-components";
import { useStoreActions } from "../../../lib/model";
import mediaQueries from "../../../utils/mediaQueries";
import { selectColor, selectSize } from "../../../utils/themeUtils";
import CartWidget from "../../cart/components/CartWidget";
import VeloPlusIcon from "../../icons/components/VeloPlusIcon";
import ProfileWidget from "../../profile/components/ProfileWidget";
import AssortmentRangeWidget from "../../profile/components/AssortmentRangeWidget";
import SearchBarWrapper from "../../search/components/SearchBarWrapper";
import Hamburger from "./Hamburger";
import PageColumn from "./PageColumn";
import dynamic from "next/dynamic";
import WishlistWidget from "../../profile/components/WishlistWidget";

const SearchOverlayDesktop = dynamic({
  loader: () => import("../../search/components/SearchOverlayDesktop"),
  ssr: false,
});

const Base = styled.div`
  color: ${selectColor("textWhite")};
  background-color: ${selectColor("dark")};
`;

const Content = styled(PageColumn)`
  display: grid;
  position: relative;

  height: 100%;
  align-items: center;
  grid-template-areas:
    "logo actions"
    "searchbar searchbar";
  grid-template-rows: ${selectSize("mobileHeaderMainActionsHeight")}px ${selectSize(
      "mobileHeaderMainActionsHeight",
    )}px;

  ${mediaQueries.desktop`
    grid-template-rows: ${selectSize("desktopHeaderMainActionsHeight")}px ;
  transform: none;
     grid-template-areas:
    "logo searchbar actions"
    `}
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  margin-left: auto;
  z-index: 1;
  grid-area: actions;
  & > * {
    margin-left: 13px;
  }
`;

const MobileHamburger = styled(Hamburger)`
  margin-right: 10px;
  margin-left: -5px;

  z-index: 1;

  ${mediaQueries.desktop`
    display: none;`}
`;

const Logo = styled(VeloPlusIcon)`
  position: relative;
  height: 26px;
  top: 3.5px;
  ${mediaQueries.desktop`
    height: 36px;
    top: 0px;
    `}
`;

const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-area: searchbar;
`;

const LogoWrapper = styled.div`
  flex-shrink: 0;
`;
export type HeaderMainActionsProps = {};

const HeaderMainActions: React.VFC<HeaderMainActionsProps> = () => {
  const toggleMobileNav = useStoreActions((s) => s.navigation.toggleMobileNav);

  return (
    <Base>
      <Content>
        <LogoWrapper>
          <Link href="/">
            <a style={{ zIndex: 1 }}>
              <Logo />
            </a>
          </Link>
        </LogoWrapper>
        <Actions>
          <ProfileWidget />
          <AssortmentRangeWidget />
          <WishlistWidget />

          <CartWidget />
        </Actions>

        <SearchWrapper>
          <MobileHamburger onClick={toggleMobileNav} />
          <SearchBarWrapper />

          <SearchOverlayDesktop />
        </SearchWrapper>
      </Content>
    </Base>
  );
};

export default React.memo(HeaderMainActions);
