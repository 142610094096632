import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { BaseProductParts } from "../queries";
import type { BaseProductParts as BaseProductPartsType } from "../queries/types/BaseProductParts";
import getProductIdFromSlug from "../utils/getProductIdFromSlug";
import type {
  UseBaseProduct,
  UseBaseProduct_product,
  UseBaseProductVariables,
} from "./types/UseBaseProduct";

export const QUERY = gql`
  query UseBaseProduct($productId: ID!, $mediaTags: [String!] = []) {
    product(productId: $productId) {
      ...BaseProductParts
    }
  }
  ${BaseProductParts}
`;

const isConfigurableProduct = (
  product: UseBaseProduct_product,
): product is BaseProductPartsType =>
  product?.__typename === "ShopConfigurableProduct";

const useBaseProduct = ({
  slug,
  returnPartialData = false,
  color,
}: {
  slug: string;
  returnPartialData?: boolean;
  color?: string;
}) => {
  const { baseProductId: productId } = getProductIdFromSlug(slug);

  const { data, previousData, error, loading } = useQuery<
    UseBaseProduct,
    UseBaseProductVariables
  >(QUERY, {
    variables: {
      productId,
      mediaTags:
        color && color !== "_colorless_" ? [`vendor-color-name:${color}`] : [],
    },
    returnPartialData,
    skip: !productId,
  });

  const theData = data ?? previousData;

  const baseProduct: BaseProductPartsType = isConfigurableProduct(
    theData?.product,
  )
    ? theData.product
    : null;

  const colorOptions = baseProduct?.vpsActiveColorVariants ?? [];

  const infoBoxes =
    baseProduct?.vpsPrimaryAssortmentPath?.assortmentProduct?.assortment
      ?.productInfoBoxes;

  const variantsDistinctionTitle =
    baseProduct?.variations?.find((v) => v.key === "variant")?.texts?.title ??
    (loading ? null : "Variante");

  // we don't have isLongTail property on base product
  const isLongTail = colorOptions?.every((o) =>
    o.products.every((o) => o.vpsIsLongTail),
  );
  return {
    baseProduct,
    error,
    loading,
    colorOptions,
    infoBoxes,
    variantsDistinctionTitle,
    isLongTail,
  };
};

export default useBaseProduct;
