import React from "react";
import styled from "styled-components";
import type { Color } from "../../../config/theme";
import { selectColor } from "../../../utils/themeUtils";

const SVG = styled.svg<{ color: Color }>`
  height: 15px;
  circle {
    fill: ${(p) => selectColor(p.color)};
  }
`;

export interface CheckBadgeIconProps {
  style?: React.CSSProperties;
  className?: string;
  color?: Color;
}

const CheckBadgeIcon: React.FC<CheckBadgeIconProps> = ({
  style,
  className,
  color = "petrol",
}) => (
  <SVG viewBox="0 0 15 15" style={style} className={className} color={color}>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(1 1)">
        <circle fill="#478392" cx="6.5" cy="6.5" r="7.5" />
        <path
          stroke="#FFF"
          strokeWidth="2"
          d="M3.03768 7.20786L5.76893 10l4.25075-6.96562"
        />
      </g>
    </g>
  </SVG>
);

export default CheckBadgeIcon;
