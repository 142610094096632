import type { NavItemsQueryType } from "../../navigation/types";
import useProductWithVariant from "../../product/hooks/useProductWithVariant";
import makeProductNavItem from "../../product/utils/makeProductNavItem";

const ShopProductNavitemQuery: NavItemsQueryType = (props) => {
  const { productOrVariant } = useProductWithVariant({
    slug: props.slug,
  });
  const basePath = props.path.split("/").slice(0, -1).join("/");
  return props.children({
    currentNavItem: productOrVariant
      ? {
          ...makeProductNavItem(productOrVariant, basePath),
          title: productOrVariant?.texts?.title,
          slug: props.slug,
          type: "internal",
        }
      : null,
    navItems: [],
    loading: false,
  });
};

export default ShopProductNavitemQuery;
