import dynamic from "next/dynamic";
import React from "react";
import styled from "styled-components";
import { selectColor } from "../../../utils/themeUtils";
import LeftSubNavigation from "../../navigation/components/LeftSubNavigation";
import SeoBreadcrumbs from "../../navigation/components/SeoBreadcrumbs";
import useBreadcrumbPaths from "../../navigation/hooks/useBreadcrumbPaths";
import type { NavItemsQueryType } from "../../navigation/types";
import ContactBox from "../../stores/components/ContactBox";
import PageContentLayout from "./PageContentLayout";
import PageLayoutNavigationStuff from "./PageLayoutNavigationStuff";
const LastVisitedProducts = dynamic({
  loader: () => import("../../profile/components/LastVisitedProducts"),
  ssr: false,
});

const Background = styled.div<{ backgroundColor: BackgroundColors }>`
  background-color: ${selectColor((p) => p.backgroundColor)};
  min-height: 100vh;
  overflow: hidden;
`;

const DestkopLeftSubNaviItemWrapper = styled.div`
  margin-bottom: 40px;
`;

export type BackgroundColors = "white" | "almostWhite";

export interface PageLayoutProps {
  fullWidthHeader?: any;
  backgroundColor?: BackgroundColors;
  showDesktopSubNavigation?: boolean;
  desktopLeftSubNaviAdditionalContent?: React.ReactNode;
  showLastVisitedProducts?: boolean;
  showBreadcrumbs?: boolean;
  renderBreadcrumb?: () => React.ReactNode;
  /**
   * Nav item query is used to resolve navigation entries based on the current path
   */
  NavItemsQuery: NavItemsQueryType;
}

const PageLayout: React.FC<PageLayoutProps> = ({
  backgroundColor = "white",
  children,
  showDesktopSubNavigation,
  desktopLeftSubNaviAdditionalContent,
  fullWidthHeader = null,
  showBreadcrumbs = true,
  showLastVisitedProducts = true,
  renderBreadcrumb,
  NavItemsQuery,
}) => {
  const breadcrumbPaths = useBreadcrumbPaths();

  const shouldShowSubnav = showDesktopSubNavigation && breadcrumbPaths[1];

  const hasDesktopLeftcontent =
    shouldShowSubnav || desktopLeftSubNaviAdditionalContent;
  return (
    <>
      <PageLayoutNavigationStuff NavItemsQuery={NavItemsQuery} />
      <SeoBreadcrumbs NavItemQuery={NavItemsQuery} />
      <Background backgroundColor={backgroundColor}>
        <PageContentLayout
          fullWidthHeader={fullWidthHeader}
          desktopLeftContent={
            hasDesktopLeftcontent ? (
              <>
                {shouldShowSubnav ? (
                  <DestkopLeftSubNaviItemWrapper>
                    <LeftSubNavigation
                      NavItemQuery={NavItemsQuery}
                      path={breadcrumbPaths[1].path}
                      slug={breadcrumbPaths[1].slug}
                    />
                  </DestkopLeftSubNaviItemWrapper>
                ) : null}
                {desktopLeftSubNaviAdditionalContent ? (
                  <DestkopLeftSubNaviItemWrapper>
                    {desktopLeftSubNaviAdditionalContent}
                  </DestkopLeftSubNaviItemWrapper>
                ) : null}
                {shouldShowSubnav || desktopLeftSubNaviAdditionalContent ? (
                  <DestkopLeftSubNaviItemWrapper>
                    <ContactBox />
                  </DestkopLeftSubNaviItemWrapper>
                ) : null}
              </>
            ) : null
          }
          showBreadcrumbs={showBreadcrumbs}
          renderBreadcrumb={renderBreadcrumb}
        >
          {children}
        </PageContentLayout>
      </Background>
      {showLastVisitedProducts ? <LastVisitedProducts /> : null}
    </>
  );
};

export default PageLayout;
