import dynamic from "next/dynamic";
import React, { useRef } from "react";
import styled from "styled-components";
import {
  ConfigAssortmentRangeMode,
  ShopVpsAssortmentRange,
} from "../../../types/global-types";
import useBaseConfig from "../../core/hooks/useBaseConfig";
import AssortmentRangeIcon from "../../icons/components/AssortmentRangeIcon";
import PlusIcon from "../../icons/components/PlusIcon";
import { usePreferences } from "../hooks/preferences";

const AssortmentRangePopup = dynamic({
  loader: () => import("./AssortmentRangePopup"),
  ssr: false,
});
const Base = styled.div``;

export interface AssortmentRangeWidgetProps {
  style?: React.CSSProperties;
  className?: string;
}

const IconWrapper = styled.div`
  cursor: pointer;
  position: relative;
`;

const ExtendedIndicator = styled(PlusIcon).attrs({ inverted: true })`
  width: 16.5px;
  height: 16.5px;
  position: absolute;
  bottom: -1px;
  right: -5px;
`;
const AssortmentRangeWidget: React.FC<AssortmentRangeWidgetProps> = ({
  style,
  className,
}) => {
  const contentRef = useRef<HTMLDivElement>();

  const isSelectable =
    useBaseConfig().data?.config?.assortmentRangeMode ===
    ConfigAssortmentRangeMode.USER_SELECT;
  const userHasExtendedMode =
    usePreferences().data?.assortmentRange === ShopVpsAssortmentRange.extended;

  return isSelectable ? (
    <Base
      style={style}
      className={className}
      data-testid="AssortmentRangeWidget"
    >
      <div>
        <IconWrapper ref={contentRef}>
          <AssortmentRangeIcon />
          {userHasExtendedMode ? <ExtendedIndicator /> : null}
        </IconWrapper>
        <AssortmentRangePopup parentRef={contentRef} />
      </div>
    </Base>
  ) : null;
};

export default AssortmentRangeWidget;
