import Link from "next/link";
import { useRouter } from "next/router";
import React, { Children } from "react";

export type ChildIsActiveFunc = (
  path: string,
  { href, as }: { href: string; as: string },
) => boolean;
export interface LinkWithActiveProps {
  style?: React.CSSProperties;
  className?: string;
  href?: string;
  as?: string;
  customChildIsActive?: ChildIsActiveFunc;
}

const LinkWithActive: React.FC<LinkWithActiveProps> = ({
  children,
  as,
  href,
  customChildIsActive,
  ...rest
}) => {
  const router = useRouter();
  const child = Children.only(children as any);
  const isHtmlElement = typeof child.type === "string";
  const path = router?.asPath?.split("?")[0];

  const isChildActive = router
    ? customChildIsActive
      ? customChildIsActive(path, { href, as })
      : path.startsWith(href) || path.startsWith(as)
    : false;
  return (
    <Link {...rest} href={href} as={as} passHref>
      {isHtmlElement
        ? child
        : React.cloneElement(child, {
            isActive: router ? path === href || path === as : false,
            isChildActive,
          })}
    </Link>
  );
};

export default LinkWithActive;
