import React from "react";
import styled from "styled-components";
import mediaQueries from "../../../utils/mediaQueries";
import { selectColor } from "../../../utils/themeUtils";
import ScanButton from "../../scanner/components/ScanButton";
import SearchBar from "./SearchBar";

const Base = styled.div`
  display: flex;
  width: 100%;
`;
const Inner = styled.div`
  width: 100%;
  background-color: ${selectColor("searchFieldBackground")};
  padding-left: 10px;
  padding-right: 10px;
  ${mediaQueries.desktop`
     max-width: 500px;
     min-height: 30px;
     padding-left: 20px;
  padding-right: 20px;
    `}

  border-radius: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  :focus-within {
    background-color: ${selectColor("white")};
  }
`;

export interface DesktopSearchBarProps {
  style?: React.CSSProperties;
  className?: string;
}

const DesktopSearchBar: React.FC<DesktopSearchBarProps> = ({
  style,
  className,
}) => {
  return (
    <Base style={style} className={className}>
      <Inner>
        <SearchBar variant="desktop" />
      </Inner>
      <ScanButton style={{ marginLeft: 10 }} />
    </Base>
  );
};

export default DesktopSearchBar;
