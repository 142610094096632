import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { BookmarkParts } from "../queries";
import type {
  UseBookmarks,
  UseBookmarks_me_bookmarks_product_ShopSimpleProduct,
} from "./types/UseBookmarks";

export const GET_BOOKMARKS = gql`
  query UseBookmarks {
    me {
      _id
      bookmarks {
        ...BookmarkParts
      }
    }
  }
  ${BookmarkParts}
`;

const useBookmarks = () => {
  const { data, error, loading } = useQuery<UseBookmarks>(GET_BOOKMARKS, {
    fetchPolicy: "cache-and-network",
    // see https://github.com/apollographql/apollo-client/issues/7068
    nextFetchPolicy: "cache-first",
    ssr: false,
  });

  const products: UseBookmarks_me_bookmarks_product_ShopSimpleProduct[] =
    data?.me?.bookmarks
      ?.filter((b) => b.product.__typename === "ShopSimpleProduct")
      .map(
        (b) => b.product as UseBookmarks_me_bookmarks_product_ShopSimpleProduct,
      );

  return { products, error, loading };
};

export default useBookmarks;
