import {
  CHECKOUT_PATH,
  PROFILE_PATH,
  SEARCH_PAGE_PATH,
  SHOP_PATH,
} from "../../../config/locations";
import type { NavItemsQueryType } from "../../navigation/types";
import ShopProductNavitemQuery from "./ShopProductNavitemQuery";
import ShopProfileNavItemsQuery from "./ShopProfileNavItemsQuery";
import ShopRootNavItemsQuery from "./ShopRootNavItemsQuery";
import ShopSubNavItemsQuery from "./ShopSubNavItemsQuery";

const ShopNavItemsQuery: NavItemsQueryType = (props) => {
  const isOnProfile = props.path === PROFILE_PATH;
  if (isOnProfile) {
    return <ShopProfileNavItemsQuery {...props} />;
  }

  // last condition is when we are NOT beneth "/shop", which is only the case for _errors or stuff like password forgotten
  const isOnRoot =
    props.slug === "" ||
    props.slug === "shop" ||
    !props.path.startsWith(SHOP_PATH);

  const isProductSlug = /[0-9]{8,}(\.[0-9]+)?$/.test(props.slug);
  const isSearch = props.path?.startsWith(SEARCH_PAGE_PATH);
  const ischeckout = props.path?.startsWith(CHECKOUT_PATH);

  if (isProductSlug) {
    return <ShopProductNavitemQuery {...props} />;
  }
  if (isProductSlug || isSearch || ischeckout)
    return props.children({
      currentNavItem: null,
      navItems: [],
      loading: false,
    });
  return isOnRoot ? (
    <ShopRootNavItemsQuery {...props} />
  ) : (
    <ShopSubNavItemsQuery {...props} />
  );
};

export default ShopNavItemsQuery;
