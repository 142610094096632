const getProductIdFromSlug = (slug: string) => {
  // we actually encode the id in the slug
  const parts = slug.split("-");
  const fullProductId = parts[parts.length - 1];

  const [baseProductId] = fullProductId.split(".");

  return { baseProductId, fullProductId };
};
export default getProductIdFromSlug;
