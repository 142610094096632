import { makeFullUrl } from "../../../utils/makeFullUrl";
import useBreadcrumbPaths from "../hooks/useBreadcrumbPaths";
import type { InternalNavItem, NavItemsQueryType } from "../types";

const DEBUG = false;
const SeoBreadcrumbs: React.FC<{
  NavItemQuery: NavItemsQueryType;
}> = ({ NavItemQuery }) => {
  const allCrumbs = useBreadcrumbPaths();
  if (!allCrumbs.length) return null;

  return allCrumbs.reduce((acc, crumb) => {
    return (n) => (
      <NavItemQuery path={crumb.path} slug={crumb.slug}>
        {({ currentNavItem }) => {
          const all = [currentNavItem, ...n];

          if (acc) {
            return acc(all);
          } else {
            const itemList = [
              {
                name: "Start",
                item: makeFullUrl("/"),
              },
              ...all
                .map((item, index) => {
                  const isLast = index === all.length - 1;

                  if (isLast && item) {
                    return {
                      name: item.title,
                      // last is optional. its better for us to not use it, because we don't have to deal with query params then
                    };
                  }
                  if (item) {
                    return {
                      name: item.title,
                      item: makeFullUrl(item.path),
                    };
                  }
                })
                .filter(Boolean),
            ].map(({ item, name }, index) => ({
              "@type": "ListItem",
              position: index + 1,
              name,
              item,
            }));

            return (
              <>
                {DEBUG ? <pre>{JSON.stringify(itemList, null, 2)}</pre> : null}
                <script
                  type="application/ld+json"
                  dangerouslySetInnerHTML={{
                    __html: JSON.stringify(
                      {
                        "@context": "https://schema.org",
                        "@type": "BreadcrumbList",
                        itemListElement: itemList,
                      },
                      null,
                      2,
                    ),
                  }}
                />
              </>
            );
          }
        }}
      </NavItemQuery>
    );
  }, null as (items: InternalNavItem[]) => React.ReactElement)([]);
};

export default SeoBreadcrumbs;
