import React, { useEffect, useRef, useState } from "react";
import { RemoveScroll } from "react-remove-scroll";
import { Sticky } from "react-sticky";
import styled from "styled-components";
import { useSize } from "../../../hooks/themeHooks";
import { useStoreState } from "../../../lib/model";
import mediaQueries from "../../../utils/mediaQueries";
import {
  hideScrollbars,
  iff,
  selectSize,
  selectZIndex,
} from "../../../utils/themeUtils";
import HeaderContextNav from "../../navigation/components/HeaderContextNav";
import MainNavigationDesktop from "../../navigation/components/MainNavigationDesktop";
import MainNavigationMobile from "../../navigation/components/MainNavigationMobile";
import type { NavItemsQueryType } from "../../navigation/types";
import useMe from "../../profile/hooks/useMe";
import HeaderMainActions from "./HeaderMainActions";

const MobileNavBarWrapper = styled.div<{ visible: boolean; isSticky: boolean }>`
  position: absolute;
  z-index: ${selectZIndex("mobileNavBarContainer")};
  top: 0px;
  left: 0px;
  width: 100%;
  transition: 0.3s;

  ${iff((p) => !p.visible)`
    left: -100%;
  `}

  ${iff((p) => p.isSticky)`
    height: 100vh;
    `.else`
      height: calc(100vh - ${selectSize("mobileHeaderContextNavHeight")}px);
      ${mediaQueries.desktop`
        height: calc(100vh - ${selectSize("desktopHeaderContextNavHeight")}px);
      `};
    `}

  overflow: auto;
  ${hideScrollbars}
`;

const HeaderMainActionsWrapper = styled.div`
  ${mediaQueries.desktop`
    transform: none !important; /* override style*/
    transition: none !important;
    `}
`;

const HeaderMainActionsContainer: React.VFC<{
  topOffset: number;
  distanceFromTop: number;
}> = ({ distanceFromTop, topOffset }) => {
  /* funky stuff:
           show the full mobile header when:
           - its on top of the page and not scrolled far. in this case it should behave as not beeing sticky
           - scroll it away as we scroll further down
           - show the full header when user scrolled up, or when count in cart changed

          */
  // normally its not good to trigger state update like this, but its ok, it works

  const [showFullHeader, setShowFullHeader] = useState(false);

  const { cartCount } = useMe();

  const [scrolledUp, setScrolledUp] = useState(false);
  useEffect(() => {
    setShowFullHeader(true);
    const handle = setTimeout(() => {
      setShowFullHeader(false);
    }, 2000);

    return () => clearTimeout(handle);
  }, [cartCount]);

  const lastPosRef = useRef(0);
  const delta = lastPosRef.current - distanceFromTop;
  lastPosRef.current = distanceFromTop;
  useEffect(() => {
    if (delta < 0) {
      setScrolledUp(true);
    }
    if (delta > 0) {
      setScrolledUp(false);
    }
  }, [delta]);

  const fullOffset = -topOffset - distanceFromTop;
  const isAfterStickyPosition = fullOffset >= 50;

  const offset =
    showFullHeader || scrolledUp
      ? 0
      : isAfterStickyPosition
      ? 50
      : Math.max(fullOffset, 0);

  return (
    <HeaderMainActionsWrapper
      style={{
        transition: isAfterStickyPosition || scrolledUp ? `300ms` : undefined,
        transform: `translateY(-${offset}px)`,
      }}
    >
      <HeaderMainActions />
    </HeaderMainActionsWrapper>
  );
};

const StickyPart: React.VFC<{
  topOffset: number;
  distanceFromTop: number;
  isSticky: boolean;

  NavItemsQuery: NavItemsQueryType;
}> = ({ NavItemsQuery, isSticky, distanceFromTop, topOffset }) => {
  const mobileNavVisible = useStoreState((s) => s.navigation.mobileNavVisible);

  return (
    <>
      <HeaderContextNav />
      <HeaderMainActionsContainer
        distanceFromTop={distanceFromTop}
        topOffset={topOffset}
      />
      <MainNavigationDesktop NavItemsQuery={NavItemsQuery} />
      <RemoveScroll enabled={mobileNavVisible}>
        <MobileNavBarWrapper visible={mobileNavVisible} isSticky={isSticky}>
          <MainNavigationMobile NavItemsQuery={NavItemsQuery} />
        </MobileNavBarWrapper>
      </RemoveScroll>
    </>
  );
};
const PageLayoutNavigationStuff: React.FC<{
  NavItemsQuery: NavItemsQueryType;
}> = React.memo(({ NavItemsQuery }) => {
  const benefitsNavVisible = useStoreState(
    (s) => s.navigation.benefitsNavVisible,
  );
  const benefitsSize = useSize("headerBenefitsHeight");
  const topOffset = benefitsNavVisible ? benefitsSize : 0;

  return (
    <>
      <Sticky topOffset={topOffset}>
        {({ isSticky, style: stickyStyle, distanceFromTop }) => {
          return (
            <div style={{ zIndex: 10, position: "relative", ...stickyStyle }}>
              <StickyPart
                topOffset={topOffset}
                NavItemsQuery={NavItemsQuery}
                isSticky={isSticky}
                distanceFromTop={distanceFromTop}
              />
            </div>
          );
        }}
      </Sticky>
    </>
  );
});
export default PageLayoutNavigationStuff;
