import { useEffect, useRef } from "react";
import styled from "styled-components";
import { useStoreActions, useStoreState } from "../../../lib/model";
import { iff, selectColor, selectFont } from "../../../utils/themeUtils";
import CloseIcon from "../../icons/components/CloseIcon";
import SearchIcon from "../../icons/components/SearchIcon";
import { useTranslation } from "../../../hooks/useTranslation";
import { useRouter } from "next/router";
import { SEARCH_PAGE_PATH } from "../../../config/locations";

type Variant = "mobile" | "desktop";

const DeleteButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;

  svg {
    display: inline-block;
    vertical-align: middle;
  }
  g {
    stroke: ${selectColor("white")};
    stroke-width: 2;
  }

  :focus {
    outline: 0;
  }
`;

const SearchButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;

  svg {
    display: inline-block;
    vertical-align: middle;
  }
  g {
    stroke: ${selectColor("white")};
    stroke-width: 2;
  }
  :focus {
    outline: 0;
  }
`;

const SearchField = styled.input<{ variant: Variant }>`
  width: 100%;
  border: none;
  outline: none;
  text-overflow: ellipsis;
  ${selectFont("searchField")};
  background: transparent;
  ${iff((p) => p.variant === "mobile")`
    color: ${selectColor("black")};
    margin-bottom: 4px;
  `};

  ${iff((p) => p.variant === "desktop")`
   

    &, &::placeholder {
      color: ${selectColor("searchFieldText")};
    }
    &:focus-within {
     
      color: ${selectColor("black")};
    }
  `};
`;

const Base = styled.div<{ variant: Variant }>`
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  ${SearchButton} g {
    stroke: ${selectColor("petrol")};
  }

  ${iff((p) => p.variant === "mobile")`
   
    color: ${selectColor("black")};
    border-bottom: 1px solid black;
    margin: 8px 5px 10px 0;
    ${DeleteButton} g{
        stroke: ${selectColor("black")};
    }
  `};

  ${iff((p) => p.variant === "desktop")`
    color: ${selectColor("searchFieldText")};
    ${DeleteButton} g{
      stroke: ${selectColor("searchFieldText")};
    }

    :focus-within {
      color: ${selectColor("black")};
      ${DeleteButton} g{
        stroke: ${selectColor("black")};
      }
      ${SearchField} {
   
        color: ${selectColor("black")};
      }
    }
  `};
`;

export interface SearchBarProps {
  variant?: Variant;
}

const SearchBar: React.FC<SearchBarProps> = ({ variant }) => {
  const inputRef = useRef<HTMLInputElement>();
  const router = useRouter();
  const { t } = useTranslation();
  const setSearchTerm = useStoreActions((s) => s.navigation.setSearchTerm);
  const searchTerm = useStoreState((s) => s.navigation.searchTerm);
  const gotoSearchResults = useStoreActions(
    (s) => s.navigation.gotoSearchResults,
  );
  const setSearchOverlayVisible = useStoreActions(
    (s) => s.navigation.setSearchOverLayVisible,
  );

  useEffect(() => {
    if (router.pathname !== SEARCH_PAGE_PATH) {
      return;
    }

    const initialSearchTerm = router.query.q;
    if (typeof initialSearchTerm === "string" && initialSearchTerm) {
      setSearchTerm(initialSearchTerm);
    }
  }, [router.pathname, router.query.q, setSearchTerm]);

  useEffect(() => {
    if (variant === "mobile" && inputRef.current) {
      inputRef.current.focus();
    }
  }, [variant]);

  const showOverlay = (e) => {
    setSearchOverlayVisible(true);
    setSearchTerm(e.target.value);
  };

  return (
    <Base variant={variant}>
      <SearchField
        variant={variant}
        ref={inputRef}
        placeholder={t("searchPlaceholder")}
        value={searchTerm}
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            // small delay so that in case of single result, me might get an answer already
            setTimeout(gotoSearchResults, 300);
          }
        }}
        onClick={() => setSearchOverlayVisible(true)}
        onChange={showOverlay}
      />
      <DeleteButton
        style={{ visibility: searchTerm ? "visible" : "hidden" }}
        onClick={() => {
          if (inputRef.current) {
            inputRef.current.focus();
            setSearchTerm("");
          }
        }}
      >
        <CloseIcon />
      </DeleteButton>
      <SearchButton
        onClick={() => {
          if (searchTerm) {
            gotoSearchResults();
          } else {
            if (inputRef.current) {
              inputRef.current.focus();
            }
          }
        }}
      >
        <SearchIcon />
      </SearchButton>
    </Base>
  );
};

export default SearchBar;
