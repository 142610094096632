import Link from "next/link";
import React, { useRef } from "react";
import styled from "styled-components";
import { PROFILE_PATH } from "../../../config/locations";
import ProfileIcon from "../../icons/components/ProfileIcon";
import dynamic from "next/dynamic";

import useMe from "../hooks/useMe";
import CheckBadgeIcon from "../../icons/components/CheckBadgeIcon";
import { selectColor } from "../../../utils/themeUtils";

const LoginPopup = dynamic({
  loader: () => import("./LoginPopup"),
  ssr: false,
});
const Base = styled.div``;

export interface ProfileWidgetProps {
  style?: React.CSSProperties;
  className?: string;
}

const ProfileIconWrapper = styled.div`
  cursor: pointer;
`;

const Anchor = styled.a`
  position: relative;
  cursor: pointer;

  &:hover {
    > svg {
      g {
        stroke: ${selectColor("petrol")};
      }
    }
  }
`;

const CheckIconWrapper = styled.div`
  position: absolute;
  bottom: -2px;
  right: -7px;
`;

const ProfileWidget: React.FC<ProfileWidgetProps> = ({ style, className }) => {
  const { isLoggedIn } = useMe();
  const contentRef = useRef<HTMLDivElement>();

  return (
    <Base style={style} className={className} data-testid="ProfileWidget">
      {isLoggedIn ? (
        <Link href={PROFILE_PATH} passHref>
          <Anchor>
            <ProfileIcon />
            <CheckIconWrapper>
              <CheckBadgeIcon />
            </CheckIconWrapper>
          </Anchor>
        </Link>
      ) : (
        <div>
          <ProfileIconWrapper ref={contentRef}>
            <ProfileIcon />
          </ProfileIconWrapper>
          <LoginPopup disabled={isLoggedIn} parentRef={contentRef} />
        </div>
      )}
    </Base>
  );
};

export default ProfileWidget;
