import React from "react";
import styled from "styled-components";

const SVG = styled.svg`
  path {
    fill: ${(p) => p.color};
  }
`;

export interface ScanIconProps {
  style?: React.CSSProperties;
  className?: string;
  color?: any;
}

const ScanIcon: React.FC<ScanIconProps> = ({
  style,
  className,
  color = "white",
}) => (
  <SVG viewBox="0 0 200 200" style={style} className={className} color={color}>
    <path d="M19.65 172.3c-4.15-1.5-8.5-5.95-10-10.15-.45-1.35-.65-5.2-.65-13.5V137h8v10.65c.05 10.05.1 10.7 1.15 12.8a7.46 7.46 0 0 0 3.4 3.4c2.1 1.05 2.75 1.1 12.85 1.15H45v8l-11.85-.05c-7.75-.05-12.45-.3-13.5-.65zM157 169v-4h10.65c10.05-.05 10.7-.1 12.8-1.15a7.46 7.46 0 0 0 3.4-3.4c1.05-2.1 1.1-2.75 1.15-12.8V137h8v11.65c0 8.3-.2 12.15-.65 13.5a18.26 18.26 0 0 1-10.2 10.2c-1.35.45-5.2.65-13.5.65H157v-4zM33 131v-18h8v36h-8v-18zM49 131v-18h16v36H49v-18zM73 131v-18h8v36h-8v-18zM89 131v-18h8v36h-8v-18zM105 131v-18h16v36h-16v-18zM129 131v-18h8v36h-8v-18zM145 131v-18h8v36h-8v-18zM161 131v-18h8v36h-8v-18zM18.1 103.85c-1.5-1.6-1.45-4.35.05-5.75L19.3 97h163.55l1.05 1.15c1.5 1.6 1.45 4.35-.05 5.75l-1.15 1.1H19.15l-1.05-1.15zM33 71V53h8v36h-8V71zM49 71V53h16v36H49V71zM73 71V53h8v36h-8V71zM89 71V53h8v36h-8V71zM105 71V53h16v36h-16V71zM129 71V53h8v36h-8V71zM145 71V53h8v36h-8V71zM161 71V53h8v36h-8V71zM9 53.4c0-8.35.2-12.2.65-13.55a18.26 18.26 0 0 1 10.2-10.2c1.35-.45 5.2-.65 13.55-.65H45v8H34.4c-10.1.05-10.75.1-12.85 1.15a7.46 7.46 0 0 0-3.4 3.4c-1.05 2.1-1.1 2.75-1.15 12.85V65H9V53.4zM185 54.4c-.05-10.1-.1-10.75-1.15-12.85a7.46 7.46 0 0 0-3.4-3.4c-2.1-1.05-2.75-1.1-12.8-1.15H157v-8h11.65c8.3 0 12.15.2 13.5.65a18.26 18.26 0 0 1 10.2 10.2c.45 1.35.65 5.2.65 13.55V65h-8V54.4z" />
  </SVG>
);

export default ScanIcon;
