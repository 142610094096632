import React from "react";
import styled from "styled-components";
import { selectFont } from "../../../utils/themeUtils";

const WhatsAppIcon = dynamic(
  () => import("../../icons/components/WhatsAppIcon"),
  {
    ssr: false,
  },
);
const PhoneIcon = dynamic(() => import("../../icons/components/PhoneIcon"), {
  ssr: false,
});

import usePrimaryContact from "../hooks/usePrimaryContact";
import { useTranslation } from "../../../hooks/useTranslation";
import StyledLink from "../../content/components/StyledLink";
import dynamic from "next/dynamic";
import Box from "../../layout/components/Box";

interface BaseProps {
  small: boolean;
}

const Base = styled(Box)<BaseProps>`
  display: flex;
  flex-direction: column;
  padding: 14px 14px;

  max-width: ${(p) => (p.small ? "255px" : "auto")};
`;

const ContactRow = styled.div`
  ${selectFont("sandBox")};
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-bottom: 15px;

  &:last-child {
    padding-bottom: 0px;
  }
`;

const IconWrapper = styled.div`
  width: 25px;
  height: 25px;
  margin-right: 11px;
  display: flex;
  align-items: center;
`;

const RowContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const RowText = styled.span`
  white-space: pre-wrap;
  max-width: 190px;
`;

const Label = styled.span`
  display: block;
`;

export interface ContactData {
  icon: any;
  label: string;
  text: string;
}

export interface ContactBoxProps {
  style?: React.CSSProperties;
  className?: string;
  small?: boolean;
}

const ContactBox: React.FC<ContactBoxProps> = ({
  style,
  className,
  small = false,
}) => {
  const { contact } = usePrimaryContact();
  const { t } = useTranslation();
  if (!contact) {
    return null;
  }
  const contactData = [
    { key: "phone", labelKey: "phoneSupport", icon: <PhoneIcon /> },
    { key: "whatsapp", labelKey: "whatsappChat", icon: <WhatsAppIcon /> },
    { key: "openingHours", labelKey: null, icon: null },
  ];

  return (
    <Base style={style} className={className} small={small}>
      {contactData
        .filter((c) => contact[c.key]?.trim())
        .map((c) => (
          <ContactRow key={c.key}>
            <IconWrapper>{c.icon}</IconWrapper>
            <RowContent>
              <RowText>
                {c.labelKey ? <Label>{t(c.labelKey)}</Label> : null}
                {contact[c.key]}
              </RowText>
            </RowContent>
          </ContactRow>
        ))}
      {contact.hint ? (
        <ContactRow>
          <IconWrapper />
          <RowContent>
            <RowText>
              <span>{contact.hint}</span>
            </RowText>
          </RowContent>
        </ContactRow>
      ) : null}
      {contact.hintLinkUrl ? (
        <StyledLink
          style={{ width: "100%", textAlign: "center" }}
          target="_blank"
          type="button"
          href={contact.hintLinkUrl}
        >
          {contact.hintLinkText || contact.hintLinkUrl}
        </StyledLink>
      ) : null}
    </Base>
  );
};

export default React.memo(ContactBox);
