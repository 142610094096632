import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useStoreActions } from "../../../lib/model";
import ScanIcon from "../../icons/components/Scan";

const Base = styled.button.attrs({ type: "button" })`
  border: none;
  appearance: none;
  background: #393939;
  cursor: pointer;
  padding: 0;
  outline: none;
  border-radius: 4px;
  padding: 4px 8px;
  &:hover {
    filter: brightness(1.2);
  }
`;

export interface ScanButtonProps {
  style?: React.CSSProperties;
  className?: string;
}

const isDeviceWithCamera = async () => {
  const isIOS =
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document);
  if (isIOS) {
    return true;
  }
  // checks if has camera (not working on ios)
  const devices = await navigator.mediaDevices.enumerateDevices?.();
  const hasVideo = devices.some((device) => device.kind === "videoinput");
  if (hasVideo) {
    return true;
  }
  return false;
};

const ScanButton: React.FC<ScanButtonProps> = ({ style, className }) => {
  const [enabled, setEnabled] = useState(false);

  const setVisible = useStoreActions((s) => s.navigation.setScannerVisible);
  // we only show scan button on devices with a camera
  useEffect(() => {
    if (process.browser) {
      isDeviceWithCamera().then(function (hasCamera) {
        setEnabled(hasCamera);
      });
    }
  }, []);

  if (!enabled) return null;

  return (
    <Base style={style} className={className} onClick={() => setVisible(true)}>
      <ScanIcon style={{ width: 24, display: "block" }} />
    </Base>
  );
};

export default ScanButton;
