import React from "react";
import styled from "styled-components";

const SVG = styled.svg`
  height: 36px;
`;

export interface VeloPlusIconProps {
  style?: React.CSSProperties;
  className?: string;
}

const VeloPlusIcon: React.FC<VeloPlusIconProps> = ({ style, className }) => (
  <SVG viewBox="0 0 129 36" style={style} className={className}>
    <defs>
      <path id="a" d="M0 .14h128.63V35.5H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          d="M125.38.14H3.25A3.25 3.25 0 0 0 0 3.38v23.71a3.25 3.25 0 0 0 3.25 3.24h8.04l4.17 5.16 4.18-5.16h105.74a3.25 3.25 0 0 0 3.25-3.24V3.38a3.25 3.25 0 0 0-3.25-3.24"
          fill="#FFF"
          mask="url(#b)"
        />
      </g>
      <path
        d="M66 24.55h4.25l.86-5.67h2.4c3.71 0 6.05-1.77 6.66-5.8.6-3.93-1.58-5.63-5.1-5.63h-6.5L66 24.55zm6.28-13.52H73c1.64 0 3.05.03 2.73 2.18-.3 2.09-1.82 2.09-3.36 2.09h-.72l.63-4.27zm14.86-3.58H82.9l-2.58 17.1h9.34l.56-3.77h-5.1l2.02-13.33zm16.4 0l-1.34 9c-.35 2.23-.87 4.65-3.6 4.65s-2.51-2.42-2.17-4.65l1.35-9h-4.25l-1.45 9.62c-.32 2.2-.56 4.37.8 6.03 1.15 1.4 3.23 1.9 5.14 1.9 1.9 0 4.11-.5 5.7-1.9 1.88-1.66 2.29-3.84 2.62-6.03l1.45-9.62h-4.25zm17.46.96A9.59 9.59 0 0 0 116.1 7a6.5 6.5 0 0 0-6.5 5.74c-.51 3.3 1.13 4.03 3.69 4.82.87.28 2.47.7 2.27 1.95-.17 1.2-1.32 1.73-2.31 1.73-1.46 0-2.58-.8-3.51-1.77l-2.37 3.58a8.6 8.6 0 0 0 5.46 1.95c1.65 0 3.43-.48 4.88-1.6 1.47-1.17 2.12-2.91 2.38-4.61.43-2.77-1.15-3.97-3.31-4.67l-1.04-.34c-.7-.25-1.78-.64-1.63-1.63.13-.96 1.26-1.43 2.04-1.43 1.04 0 1.95.45 2.64 1.13L121 8.41z"
        fill="#C31924"
      />
      <path
        d="M12.6 7.56H8l6.86 16.88h3.29L25.1 7.56h-4.6L16.54 17.8 12.6 7.56zm22.85 3.72V7.56h-9.28v16.88h9.28v-3.72h-5.04v-2.9h4.78V14.1h-4.78v-2.82h5.04zm6.86-3.72h-4.24v16.88h9.32v-3.72h-5.08V7.56zM56.98 7c-4.7 0-9.02 3.58-9.02 8.69 0 5.46 3.78 9.31 9.02 9.31C62.2 25 66 21.15 66 15.69 66 10.59 61.67 7 56.98 7zm0 4.25c2.53 0 4.6 2.13 4.6 4.46 0 2.91-2.07 5.04-4.6 5.04s-4.61-2.13-4.61-5.04a4.63 4.63 0 0 1 4.6-4.46z"
        fill="#000"
      />
    </g>
  </SVG>
);

export default VeloPlusIcon;
