import type { ProductVariantBaseProduct } from "../queries/types/ProductVariantBaseProduct";
import { getFullProductTitle } from "../utils/getFullProductTitle";
import getProductIdFromSlug from "../utils/getProductIdFromSlug";
import useBaseProduct from "./useBaseProduct";
import useSelectedVariant from "./useSelectedVariant";
const useProductWithVariant = ({
  slug,
  color,
  returnPartialData,
}: {
  slug: string;
  color?: string;
  returnPartialData?: boolean;
}) => {
  const { baseProductId: productId, fullProductId } =
    getProductIdFromSlug(slug);
  const noVariantSelected = fullProductId === productId;

  const {
    baseProduct,
    variantsDistinctionTitle,
    error: baseProductError,
    loading: baseProductLoading,
    isLongTail,
    colorOptions,
  } = useBaseProduct({ slug: productId, returnPartialData, color });

  const {
    selectedVariant,
    error: selectedVariantError,
    loading: selectedVariantLoading,
  } = useSelectedVariant({ skip: noVariantSelected, fullProductId });

  if (baseProductError) {
    // tslint:disable-next-line: no-console
    console.error(baseProductError);
  }
  if (selectedVariantError) {
    // tslint:disable-next-line: no-console
    console.error(selectedVariantError);
  }

  const selectedColorOption =
    colorOptions.find((o) =>
      o.products?.some((p) => p._id === fullProductId),
    ) ?? colorOptions.find((o) => o.value === color);

  const variants =
    selectedColorOption?.products ??
    colorOptions.reduce(
      (acc, o) => [...acc, ...(o.products ?? [])],
      [] as readonly ProductVariantBaseProduct[],
    );
  const fullProductTitle = getFullProductTitle(
    baseProduct,
    selectedColorOption,
  );
  return {
    baseProduct,
    isLongTail, // true when all variants are longtail
    baseProductLoading,
    baseProductError,

    productOrVariant: selectedVariant ?? baseProduct,
    loading: baseProductLoading || selectedVariantLoading,
    selectedVariant,
    variants,
    colorOptions,
    selectedColorOption,
    noVariantSelected,
    /**
     * the title of the distinctive feature of all variants (e.g. "Sizes")
     */
    variantsDistinctionTitle,
    /**
     * this is already set, even if the variant is loading, as we know the id beforehand from the url
     */
    selectedVariantId:
      selectedVariant?._id ??
      (productId !== fullProductId ? fullProductId : null),
    selectedVariantLoading,
    selectedVariantError,
    /**
     * the product title including the color
     */
    fullProductTitle,
  };
};

export default useProductWithVariant;
