import React, { useRef } from "react";
import styled from "styled-components";
import useBookmarks from "../hooks/useBookmarks";
import WishListIcon from "../../icons/components/WishlistIcon";
import { iff, selectColor } from "../../../utils/themeUtils";
import BubbleBadge from "../../core/components/BubbleBadge";
import dynamic from "next/dynamic";

const WishlistPopup = dynamic({
  loader: () => import("../../product/components/WishlistPopup"),
  ssr: false,
});

const Base = styled.div``;

export interface WishlistWidgetProps {
  style?: React.CSSProperties;
  className?: string;
}

const IconWrapper = styled.div<{ disabled: boolean }>`
  ${iff((p) => !p.disabled)`
    cursor: pointer;
  `}
`;

const StyledWishlistIcon = styled(WishListIcon)<{ disabled: boolean }>`
  position: relative;
  top: 2px;
  ${iff((p) => !p.disabled)`
    &:hover {
      path {
        stroke: ${selectColor("petrol")};
      }
    }
  `.else`
     path {
       stroke: ${selectColor("darkDisabled")};
     }
    
  `}
`;

const WishlistWidget: React.FC<WishlistWidgetProps> = ({
  style,
  className,
}) => {
  const { products } = useBookmarks();
  const contentRef = useRef<HTMLDivElement>();
  const productsCount = products?.length ?? 0;
  const disabled = !productsCount;

  return (
    <Base style={style} className={className}>
      <IconWrapper ref={contentRef} disabled={disabled}>
        <BubbleBadge inverted value={productsCount}>
          <StyledWishlistIcon disabled={disabled} />
        </BubbleBadge>
      </IconWrapper>

      <WishlistPopup disabled={disabled} parentRef={contentRef} />
    </Base>
  );
};

export default WishlistWidget;
