import { SHOP_PATH } from "../../../config/locations";
import type { NavItemsQueryType } from "../../navigation/types";
import makeNavItemFromAssortment, {
  makeNavItemForChildAssortment,
} from "../../navigation/utils/makeNavItemFromAssortment";
import useAssortmentWithChildren from "../hooks/useAssortmentWithChildren";

const ShopSubNavItemsQuery: NavItemsQueryType = ({
  path: pathRaw,
  children,
}) => {
  const path = pathRaw?.replace(SHOP_PATH, ""); // we now use full path,
  const { assortment, loading } = useAssortmentWithChildren({
    path,
  });
  if (assortment) {
    const navItem = makeNavItemFromAssortment(assortment);
    return children({
      currentNavItem: navItem,
      navItems: assortment.children.map((child) =>
        makeNavItemForChildAssortment(child),
      ),
    });
  }

  return children({
    currentNavItem: null,
    navItems: [],
    loading,
  });
};

export default ShopSubNavItemsQuery;
