import React from "react";
import styled from "styled-components";

const SVG = styled.svg``;

export interface AssortmentRangeIconProps {
  style?: React.CSSProperties;
  className?: string;
}

const AssortmentRangeIcon: React.FC<AssortmentRangeIconProps> = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="#FFFFFF"
    fill="none"
    strokeLinecap="square"
    //strokeLinejoin="square"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>{" "}
    <path d="M3 21v-13l9 -4l9 4v13"></path>{" "}
    <path d="M13 13h4v8h-10v-6h6"></path>{" "}
    <path d="M13 21v-9a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1v3"></path>{" "}
  </SVG>
);

export default AssortmentRangeIcon;
