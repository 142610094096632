import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import {
  ProductMedia,
  ProductTextsParts,
  ProductVariantBaseProduct,
  SimpleProductPrices,
} from "../../product/queries";
import type {
  UseSelectedVariant,
  UseSelectedVariant_product,
  UseSelectedVariant_product_ShopSimpleProduct,
} from "./types/UseSelectedVariant";
import useProductStocks from "./useProductStocks";

export type VariantProduct = UseSelectedVariant_product_ShopSimpleProduct;
// deprecated
const QUERY = gql`
  query UseSelectedVariant($productId: ID!) {
    product(productId: $productId) {
      _id

      tags
      status

      media(limit: 50) {
        ...ProductMedia
      }
      texts {
        ...ProductTextsParts
      }
      ... on ShopSimpleProduct {
        ...ProductVariantBaseProduct
        vpsIsLongTail

        vpsAttributes {
          key
          label
          value
          isProxy
        }
      }
    }
  }
  ${ProductVariantBaseProduct}
  ${ProductTextsParts}
  ${SimpleProductPrices}
  ${ProductMedia}
`;

const isSimpleProduct = (
  product: UseSelectedVariant_product,
): product is UseSelectedVariant_product_ShopSimpleProduct =>
  product?.__typename === "ShopSimpleProduct";

const useSelectedVariant = ({
  skip,
  fullProductId,
}: {
  fullProductId: string;
  skip: boolean;
}) => {
  const { data, error, loading } = useQuery<UseSelectedVariant>(QUERY, {
    variables: {
      productId: fullProductId,
    },
    skip,
  });
  // fetch availabiilty as this is always unchached
  // this avoids wrong stocks for long SSR cache durations
  // remove it from tests as it messes with the snapshots...
  // eslint-disable-next-line react-hooks/rules-of-hooks
  if (process.env.NODE_ENV !== "test") useProductStocks(fullProductId);
  const product = data?.product;
  const selectedVariant = skip
    ? null
    : isSimpleProduct(product)
    ? product
    : null;
  return {
    selectedVariant,
    error,
    loading,
  };
};

export default useSelectedVariant;
