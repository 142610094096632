import React from "react";
import styled from "styled-components";
import { iff, selectColor, selectFont } from "../../../utils/themeUtils";
import type { NavItem, NavItemsQueryType } from "../types";
import LinkWithActive from "./LinkWithActive";

const Base = styled.div``;

export interface LeftSubNavigationProps {
  style?: React.CSSProperties;
  className?: string;
  NavItemQuery: NavItemsQueryType;
  path: string;
  slug: string;
}

const Item = styled.a<{ isHeading?: boolean }>`
  border-bottom: 1px solid ${selectColor("petrol")};
  padding-top: 6px;
  padding-bottom: 9px;
  display: block;
  ${selectFont((p) =>
    p.isHeading ? "leftSubNavigationHeading" : "leftSubNavigation",
  )};
  color: ${selectColor("petrol")};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: ${selectColor("petrolHover")};
  }
  ${iff((p: any) => p.isActive || p.isChildActive)`
    color: ${selectColor("textBlack")};
`}
`;
const ChildPageNavBase = styled.ul``;
const ChildPageNav = ({ navItems }: { navItems: NavItem[] }) => (
  <ChildPageNavBase>
    {navItems.map((item, index) =>
      item.type === "internal" ? (
        <li key={index}>
          <LinkWithActive key={item.href} href={item.href} as={item.path}>
            <Item>{item.title}</Item>
          </LinkWithActive>
        </li>
      ) : (
        <li key={index}>
          <Item onClick={() => item.onClick()}>{item.title}</Item>
        </li>
      ),
    )}
  </ChildPageNavBase>
);

const LeftSubNavigation: React.FC<LeftSubNavigationProps> = ({
  style,
  className,
  NavItemQuery,
  path,
  slug,
}) => (
  <NavItemQuery path={path} slug={slug}>
    {({ navItems, currentNavItem }) => (
      <Base style={style} className={className}>
        {currentNavItem ? (
          currentNavItem.type === "internal" ? (
            <LinkWithActive href={currentNavItem.href} as={currentNavItem.path}>
              <Item isHeading>
                {currentNavItem.CustomComponent ? (
                  <currentNavItem.CustomComponent />
                ) : (
                  currentNavItem.title
                )}
              </Item>
            </LinkWithActive>
          ) : null
        ) : null}
        <ChildPageNav navItems={navItems} />
      </Base>
    )}
  </NavItemQuery>
);

export default React.memo(LeftSubNavigation);
