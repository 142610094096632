import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import type { UseProductStocks } from "./types/UseProductStocks";
import {
  DispatchesForPickupParts,
  DispatchesForShippingParts,
  VariantProductNavItemParts,
} from "../queries";

const QUERY = gql`
  query UseProductStocks($productId: ID!) {
    product(productId: $productId) {
      _id
      tags
      status
      ... on ShopSimpleProduct {
        vpsAvailability
        vpsIsLongTail
        stocksForPickup: simulatedStocks(deliveryProviderType: PICKUP) {
          _id
          warehousingProvider {
            _id
            vpsBranchTitle
          }
          quantity
        }
        stocksForShipping: simulatedStocks(deliveryProviderType: SHIPPING) {
          _id
          quantity
        }
        ...DispatchesForShippingParts
        ...DispatchesForPickupParts
        ...VariantProductNavItemParts
      }
    }
  }
  ${DispatchesForShippingParts}
  ${DispatchesForPickupParts}
  ${VariantProductNavItemParts}
`;

const useProductStocks = (productId: string) => {
  const { data, error, loading } = useQuery<UseProductStocks>(QUERY, {
    variables: {
      productId,
    },
    skip: !productId,
    // so its always fresh
    fetchPolicy: "cache-and-network",
    ssr: false,
  });

  return {
    product:
      data?.product?.__typename === "ShopSimpleProduct" ? data.product : null,
    error,
    loading,
  };
};

export default useProductStocks;
